<template>
  <div id="app">
    <router-view class="main-content" />
    <footer class="footer no-print">
      <div class="container">
        <div class="columns">
          <div class="column has-text-centered">
            <router-link to="/imprint">Impressum</router-link>
          </div>
        </div>
        <div class="columns">
          <div class="column has-text-centered">
            <small class="has-text-grey-light">v{{ version }}</small>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  computed: {
    version() {
      return process.env.VUE_APP_VERSION;
    },
  },
  watch: {
    $route(to) {
      document.title = to.meta.title || "Studienregister";
    },
  },
};
</script>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Set your colors
$primary: #2761ab;
$primary-invert: findColorInvert($primary);
$success: #00a579;
$success-invert: findColorInvert($success);

$kionet: #018dc8;
$kionet-invert: findColorInvert($kionet);

$checkbox-background-color: $white;

$radius: 0px;

// Setup $colors to use as bulma classes
$colors: (
  "success": (
    $success,
    $success-invert,
  ),
  "primary": (
    $primary,
    $primary-invert,
  ),
  "white": (
    $white,
    $black,
  ),
  "black": (
    $black,
    $white,
  ),
  "light": (
    $light,
    $light-invert,
  ),
  "dark": (
    $dark,
    $dark-invert,
  ),
  "info": (
    $info,
    $info-invert,
  ),
  "warning": (
    $warning,
    $warning-invert,
  ),
  "danger": (
    $danger,
    $danger-invert,
  ),
  "kionet": (
    $kionet,
    $kionet-invert,
  ),
);

// Footer
$footer-background-color: $white;

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
</style>

<style>
#app {
  height: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

body,
html {
  height: 100%;
  font-family: "Open Sans", "Segoe UI", -apple-system, BlinkMacSystemFont,
    "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 1.5;
  font-size: 14px;
  color: #314659;
  background-color: #f5f7fa;
}

.b-checkbox.checkbox input[type="checkbox"] + .check {
  border-width: 1px;
}

.main-content {
  flex: 1 0 auto;
}

@media print {
  h2 {
    color: #363636 !important;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }
}
</style>
