import Vue from "vue";
import Buefy from "buefy";
import VueMatomo from "vue-matomo";
import VueLogger from "vuejs-logger";
import App from "./App.vue";
import router from "./router";

const isProduction = process.env.NODE_ENV === "production";

const logOptions = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};

const hostnameToTrackingId = {
  "studien.ccc-emn.de": 1,
  "studienregister.uk-erlangen.de": 3,
  "kionet-studien.de": 4,
  default: 2,
};

Vue.use(VueLogger, logOptions);

Vue.use(Buefy);

Vue.config.productionTip = false;

const trackingId = hostnameToTrackingId[window.location.hostname] || hostnameToTrackingId.default;

Vue.$log.debug(`Tracking '${window.location.hostname}' with tracking id '${trackingId}'`);

Vue.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: "https://studien.ccc-emn.de/analytics",
  siteId: trackingId,

  // Changes the default .js and .php endpoint's filename
  // Default: 'piwik'
  trackerFileName: "matomo",

  // Overrides the autogenerated tracker endpoint entirely
  // Default: undefined
  // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

  // Enables automatically registering pageviews on the router
  router,

  // Enables link tracking on regular links. Note that this won't
  // work for routing links (ie. internal Vue router links)
  // Default: true
  enableLinkTracking: true,

  // Require consent before sending tracking information to matomo
  // Default: false
  requireConsent: false,

  // Whether to track the initial page view
  // Default: true
  trackInitialView: true,

  // Whether or not to log debug information
  // Default: false
  debug: false,

  // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
  // Default: undefined
  userId: undefined,
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
