import Vue from "vue";
import VueRouter from "vue-router";

const Imprint = () => import("@/views/ImprintView.vue");
const Home = () => import("@/views/ccc/HomeView.vue");
const Details = () => import("@/views/ccc/DetailsView.vue");
const UKErHome = () => import("@/views/uker/HomeView.vue");
const UKErDetails = () => import("@/views/uker/DetailsView.vue");
const KionetHome = () => import("@/views/kionet/HomeView.vue");
const KionetImprint = () => import("@/views/kionet/ImprintView.vue");

Vue.use(VueRouter);

const generateRoutes = () => {
  // maps from the hostname the website is accessed under to an object containing
  // views for each virtual site.
  const hostToRoutes = {};
  hostToRoutes["studienregister.uk-erlangen.de"] = {
    title: "Zentrales Studienregister des Universitätsklinikums Erlangen",
    home: UKErHome,
    details: UKErDetails,
  };
  hostToRoutes["studien.diz.uk-erlangen.de"] = hostToRoutes["studienregister.uk-erlangen.de"];

  hostToRoutes["studien.ccc-emn.de"] = {
    title: "Zentrales Studienregister des Comprehensive Cancer Center Erlangen-EMN",
    home: Home,
    details: Details,
  };

  hostToRoutes["kionet-studien.de"] = {
    title: "Studienregister des Kinderonkologischen Netzwerks (KIONET) Bayern ",
    home: KionetHome,
    details: UKErDetails,
    imprint: KionetImprint,
  };

  // the UKEr variant is the default
  hostToRoutes.default = hostToRoutes["studienregister.uk-erlangen.de"];

  // eslint-disable-next-line no-restricted-globals
  const routeData = hostToRoutes[location.hostname] || hostToRoutes.default;

  return [
    {
      path: "/",
      name: "home",
      component: routeData.home,
      props: (route) => ({
        clinic: route.query.clinic,
        // eslint-disable-next-line no-nested-ternary
        categories: route.query.category
          ? Array.isArray(route.query.category)
            ? [...route.query.category]
            : [route.query.category]
          : [],
      }),
      meta: {
        title: routeData.title,
      },
    },
    {
      path: "/imprint",
      name: "imprint",
      component: routeData.imprint || Imprint,
      meta: {
        title: routeData.title,
      },
    },
    {
      path: "/details/:id",
      name: "details",
      component: routeData.details,
      props: true,
      meta: {
        title: routeData.title,
      },
    },
  ];
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
  routes: generateRoutes(),
});

export default router;
